'use strict';

$(function () {
  //custom function showing current slide
  if ($('.slider-section').length > 0) {
    $.each($('.slider-section'), function () {
      var $status = $(this).find('.pagingInfoSlider');
      var $slickElement = $(this).find('.slider');

      $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.text(i + ' OF ' + slick.slideCount);
      });
    });
  }

  var sliderOptions = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    speed: 300,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    }, {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        infinite: true
      }
    }]
  };

  if ($('.slider').length > 0) {
    $(".product-card-carousel").slick(sliderOptions);
    $(".one-product-card-carousel").slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1
      //variableWidth: true,
    });

    $(".two-product-card-carousel").slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 2,
      variableWidth: true,
      // the magic
      responsive: [{
        breakpoint: 1024,
        settings: "unslick",
        variableWidth: true
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        }
      }, {
        breakpoint: 400,
        settings: {
          dots: false,
          arrows: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }

  if (".c-007-1".length > 0) {
    $(".c-007-1-product-card-carousel").slick({
      dots: true,
      arrows: true,
      infinite: false,
      speed: 300,
      variableWidth: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true
        }
      }, {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true
        }
      }]
    });
  }

  if (".c-014".length > 0) {
    $(".c-014 .slider").slick({
      dots: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      speed: 300,
      variableWidth: false,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          variableWidth: true
        }
      }, {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          infinite: true,
          variableWidth: true
        }
      }]
    });
  } //c-014

  if (".c-006".length > 0) {
    $(".c-006 .slider").slick({
      dots: true,
      infinite: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      speed: 300,
      variableWidth: true,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          variableWidth: true
        }
      }, {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          infinite: true,
          variableWidth: true
        }
      }]
    });
  } //c-014

  if ($('.mkp-slider').length > 0) {
    $('.mkp-slider').slick({
      infinite: false,
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }, {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }
  if ($('.c-093').length > 0) {
    var $carousel = $('.c-093 .mobile-funds');
    var slidenumberfromUrl = getUrlParameter('tab');
    var firstslidenumber = 0;
    if (!isNaN(slidenumberfromUrl) && slidenumberfromUrl > 0) firstslidenumber = slidenumberfromUrl - 1;
    console.log(firstslidenumber);
    console.log(slidenumberfromUrl);
    $($carousel).each(function (index) {
      $(window).on('load resize', function () {
        if ($(window).width() < 992) {
          if (!$carousel.hasClass('slick-initialized')) {
            $($carousel).slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              appendDots: $('.c-093 .slider-mobile-dots'),
              dots: true,
              dotsClass: 'custom-dots',
              customPaging: function customPaging(slider, i) {
                return '<a>' + (i + 1) + '</a>';
              },
              infinite: false,
              prevArrow: $('.prev'),
              nextArrow: $('.next'),
              initialSlide: firstslidenumber
            });
          }
        }
      });
    });
    $('.prev').click(function () {
      $carousel.slick('slickPrev');
    });

    $('.next').click(function () {
      $carousel.slick('slickNext');
    });
  }

  function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

  if ($('.ord-list').length > 0) {
    $(".ord-list").each(function (index) {
      $(this).not(".slick-initialized").slick({
        infinite: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        }, {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true
          }
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }, {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }]
      });
    });
  }

  if ($(".c-076").length > 0) {
    $(".c-076 .carousel-offer-cards").each(function (index) {

      var id = "slide-offer-cards-" + index;
      $(this).attr("id", id);

      $("#" + id).on("init", function (event, slick) {
        $("#" + id).find('.slick-slide').each(function (index) {
          index = index + 1;
          var gtmId = index > 9 ? "" + index : "0" + index;
          $(this).attr("gtmId", "slide" + gtmId);
        });
      });

      if (!$(this).hasClass("half-slider")) {
        $(this).not(".slick-initialized").slick({
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: true,
          appendDots: $("#" + id).siblings().find(".slider-mobile-dots"),
          customPaging: function customPaging(slider, i) {
            return '<div class="custom-dot" role="tab" aria-selected="true" tabindex="0"></div>';
          },
          responsive: [{
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              arrows: true
            }
          }, {
            breakpoint: 520,
            settings: {
              slidesToShow: 1.3,
              slidesToScroll: 1,
              dots: true
            }
          }]
        });
      } else {
        $(this).slick({
          infinite: false,
          slidesToShow: 3.2,
          slidesToScroll: 3,
          dots: true,
          arrows: true,
          appendDots: $("#" + id).siblings().find(".slider-mobile-dots"),
          customPaging: function customPaging(slider, i) {
            return '<div class="custom-dot" role="tab" aria-selected="true" tabindex="0"></div>';
          },
          responsive: [{
            breakpoint: 992,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1,
              dots: true,
              arrows: true
            }
          }, {
            breakpoint: 520,
            settings: {
              slidesToShow: 1.3,
              slidesToScroll: 1,
              dots: true
            }
          }]
        });
      }
      $("#" + id).on('afterChange', function (event, slick, currentSlide) {
        var noNextSlide = $(slick.$nextArrow).hasClass("slick-disabled");
        if (noNextSlide) {
          $("#" + id).parent().find(".slide-next").addClass("disabled");
        } else {
          $("#" + id).parent().find(".slide-next").removeClass("disabled");
        }

        var noPrevSlide = $(slick.$prevArrow).hasClass("slick-disabled");
        if (noPrevSlide) {
          $("#" + id).parent().find(".slide-prev").addClass("disabled");
        } else {
          $("#" + id).parent().find(".slide-prev").removeClass("disabled");
        }
      });
    });

    $('.slide-prev').click(function () {
      $('.carousel-offer-cards').slick('slickPrev');
    });

    $('.slide-next').click(function () {
      $('.carousel-offer-cards').slick('slickNext');
    });
  }

  if ($("#wizard").length > 0) {
    var initCarouselIndicators = function initCarouselIndicators() {
      $("#wizard .carousel-indicators[data-target]").each(function (i, indicators) {
        var targetId = indicators.dataset.target;
        if (targetId != "") {
          var $carousel = $(targetId);
          $carousel.bind('slide.bs.carousel', function (e) {
            var $targetSlide = $(e.relatedTarget);
            var index = $targetSlide.index();
            $('.carousel-indicators[data-target="' + targetId + '"] li').removeClass('active');
            $('.carousel-indicators[data-target="' + targetId + '"] li:nth-child(' + (index + 1) + ')').addClass('active');
          });
        }
      });
    };

    initCarouselIndicators();
  }

  if ($("#tabbedHero").length > 0) {
    var mobile_indicators = $(".carousel-indicators li"),
        nav_pills = $(".banner-pills .nav-pills li"),
        tabbedHero = $("#tabbedHero"),
        hammertime = new Hammer(document.getElementById('tabbedHero'));
    hammertime.on('swipe', function (ev) {
      switch (ev.direction) {
        case 2:
          tabbedHero.carousel("next");
        case 4:
          tabbedHero.carousel("prev");
      }
    });

    nav_pills.hover(function (e) {
      tabbedHero.carousel($(this).data('slide-to'));
      tabbedHero.carousel('pause');
    }, function (e) {
      tabbedHero.carousel('cycle');
    });
    tabbedHero.hover(function (e) {
      tabbedHero.carousel('pause');
    }, function (e) {
      tabbedHero.carousel('cycle');
    });

    tabbedHero.on('slid.bs.carousel', function (e) {
      nav_pills.eq($(this).find('.active').index()).addClass('active').siblings().removeClass('active');
      mobile_indicators.eq($(this).find('.active').index()).addClass('active').siblings().removeClass('active');
    });

    nav_pills.on('click', function (e) {
      var href = $(this).find('a').attr('href'),
          target = $(this).find('a').attr('target');
      target == "_blank" ? window.open(href, '_blank') : window.location = href;
    });

    mobile_indicators.on('click', function () {
      $(this).addClass('active').siblings().removeClass('active');
    });
  }

  if ($(".subnav-headline").length > 0) {
    mobileOnlySubmenuSlider(".menu-slider", false, true, 991);
  };

  if ($(".c-090 .card__wrap--outer").length > 0) {
    $(".c-090 .card__wrap--outer").each(function (index) {

      var id = "slide-cards-" + index;
      $(this).attr("id", id);

      var slideNumber = $(".c-090 .card").length;
      var slidesToShow = 2;

      if (slideNumber >= 3) {
        slidesToShow = 2.2;
      } else {
        customSlidesToShow = slideNumber;
      }

      $('.c-090 .card__wrap--outer').slick({
        infinite: false,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        appendDots: $("#" + id).siblings().find(".slider-mobile-dots"),
        customPaging: function customPaging(slider, i) {
          return '<div class="custom-dot" role="tab" aria-selected="true" tabindex="0"></div>';
        },
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: slidesToShow,
            slidesToScroll: 1
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: slidesToShow,
            slidesToScroll: 1
          }
        }, {
          breakpoint: 767,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1
          }
        }]
      });

      $("#" + id).on('afterChange', function (event, slick, currentSlide) {
        var noNextSlide = $(slick.$nextArrow).hasClass("slick-disabled");
        if (noNextSlide) {
          $("#" + id).parent().find(".slide-next").addClass("disabled");
        } else {
          $("#" + id).parent().find(".slide-next").removeClass("disabled");
        }

        var noPrevSlide = $(slick.$prevArrow).hasClass("slick-disabled");
        if (noPrevSlide) {
          $("#" + id).parent().find(".slide-prev").addClass("disabled");
        } else {
          $("#" + id).parent().find(".slide-prev").removeClass("disabled");
        }
      });
    });

    $('.slide-prev').click(function () {
      $('.card__wrap--outer').slick('slickPrev');
    });

    $('.slide-next').click(function () {
      $('.card__wrap--outer').slick('slickNext');
    });
  }
});

function mobileOnlySubmenuSlider($slidername, $dots, $arrows, $breakpoint) {
  var slider = $($slidername);
  var settings = {
    mobileFirst: true,
    infinite: false,
    dots: $dots,
    arrows: $arrows,
    prevArrow: $('.back-arrow'),
    nextArrow: $('.next-arrow'),
    responsive: [{
      breakpoint: $breakpoint,
      settings: "unslick"
    }, {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 300,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  };

  slider.slick(settings);

  $(window).on("resize", function () {
    if ($(window).width() > $breakpoint) {
      return;
    }
    if (!slider.hasClass("slick-initialized")) {
      return slider.slick(settings);
    }
  });
} // Mobile Only Slider